<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="420px"
    persistent
  >
    <v-card
      class="pa-3 rounded-lg"
      rounded
      style="width: 100%"
      color="#191919"
    >
      <v-form
        ref="form"
        @submit.prevent="onSave"
      >
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          dense
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ t('add_guest.add_guest') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('update:is-modal-open', false)">
            {{ icons.mdiClose }}
          </v-icon>
        </v-app-bar>

        <v-card-text class="mt-5 pb-3 px-2 justify-center d-flex flex-column">
          <v-text-field
            v-model="dataGuest.guest_name"
            :placeholder="t('add_guest.guests_name')"
            dense
            outlined
            rounded
            :rules="[validators.required]"
            @keypress.enter="onSave"
          ></v-text-field>

          <!-- <span class="mb-3">{{ t('add_guest.guest_info') }}</span> -->

          <!-- <div
            class="d-flex justify-space-between"
          >
            <v-select
              v-if="isPhoneVisible"
              v-model="phoneCode"
              class="pr-1 w-code"
              dense
              outlined
              rounded
              :items="phoneCodeOptions"
              item-text="text"
              item-.="value"
              :rules="[validators.required]"
              @change="() => {
                loginForm.validate()
              }"
            >
              <template v-slot:item="{ item }">
                <img
                  :src="item.image"
                  :alt="item.value"
                  width="20"
                  height="auto"
                  class="mr-2"
                >
                {{ item.text }}
              </template>
            </v-select>

            <v-text-field
              ref="phoneInput"
              v-model="code"
              class="pl-1"
              :placeholder="isPhoneVisible ? `${t('add_guest.optional')} ${t('users.phone')}` : `${t('add_guest.optional')} ${t('login.enter_phone_email')}`"
              dense
              outlined
              rounded
              :rules="isPhoneVisible ? [validators.validatePhone] : [validators.emailValidator]"
              clearable
              @input="onInputCode"
              @click:clear="onClearCode"
            />
          </div> -->
        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center py-3 px-2">
          <v-btn
            class="text-xs text-capitalize rounded-lg"
            color="secondary"
            block
            dark
            type="submit"
          >
            {{ t('tooltip.submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import { ref, computed, onMounted } from '@vue/composition-api'
import { mdiPlus, mdiDeleteOutline, mdiClose, mdiMenuDown } from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'
import useCountry from '@core/utils/useCountry'

export default {
  name: 'AddGuest',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    requiredPhone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { phoneCodeOptions } = usePhone()
    const { getCountryCode } = useCountry()

    const form = ref(null)
    const dataGuest = ref({
      guest_phone_number: null,
      guest_name: null,
    })
    const computedRequiredPhone = computed(() => props.requiredPhone)

    const isPhoneVisible = ref(false)
    const initCode = ref(null)
    const code = ref(null)
    const phoneCode = ref(null)
    const fullPhone = ref(null)

    const onClearCode = () => {
      phoneCode.value = initCode.value
      fullPhone.value = null
      isPhoneVisible.value = false
    }

    const validatePhone = e => {
      if (!/^([0-9])*$/.test(e)) {
        isPhoneVisible.value = false

        return 'Invalid number'
      }

      if (phoneCode.value && e) {
        const phoneNumberJS = parsePhoneNumber(e, phoneCode.value)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            fullPhone.value = phoneNumberJS.number

            return true
          }
          fullPhone.value = null
        }
      } else if (!code.value.includes('+')) {
        onClearCode()
      }

      return 'Invalid number'
    }

    const onInputCode = e => {
      if (!isPhoneVisible.value && e && e !== '' && e.length) {
        if (e.length > 1) {
          if (/^\+?([0-9])+$/.test(e)) {
            code.value = code.value.replace('+', '')
            phoneCode.value = initCode.value
            isPhoneVisible.value = true
          }
        }
      }
    }

    const onSave = () => {
      if (form.value.validate()) {
        dataGuest.value.guest_phone_number = isPhoneVisible.value ? fullPhone.value : code.value
        emit('add-guest', dataGuest.value)
        dataGuest.value = {
          guest_phone_number: null,
          guest_name: null,
        }
        form.value.resetValidation()
      }
    }

    onMounted(() => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
    })

    return {
      // data
      form,
      dataGuest,

      isPhoneVisible,
      initCode,
      code,
      phoneCode,
      fullPhone,
      phoneCodeOptions,

      // computed
      computedRequiredPhone,

      // methods
      onSave,
      onInputCode,
      onClearCode,

      // validations
      validators: {
        required,
        emailValidator,
        validatePhone,
      },

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }
</style>
