<template>
  <div
    class="d-flex justify-center align-center"
    :class="{'flex-row': isRow, 'flex-column': !isRow }"
  >
    <v-icon
      v-if="!isCustomIcon"
      :color="colorIcon"
      class="cursor-pointer action-icon"
    >
      {{ icon }}
    </v-icon>

    <div
      v-else
      class="cursor-pointer action-icon d-flex justify-center align-center"
    >
      <slot />
    </div>

    <div
      class="d-flex flex-column mt-1"
      :class="{'pl-2': isRow }"
    >
      <span
        class="cursor-pointer"
        style="color: #FF3232;"
      >{{ title }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { computed } from '@vue/composition-api'

export default {
  name: 'CardAction',
  props: {
    title: {
      type: String,
      required: true,
    },
    colorTitle: {
      type: String,
      default: '#FF3232',
    },
    isCustomIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Object],
      default: () => {},
    },
    sizeIcon: {
      type: Number,
      default: 20,
    },
    colorIcon: {
      type: String,
      default: 'secondary',
    },
    isRow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedTitle = computed(() => props.title)

    return {
      // computed
      computedTitle,
    }
  },
}
</script>

<style lang="scss" scoped>
.action-icon {
  background-color: #333333;
  border-radius: 100px;
  font-size: 22px;
  width: 50px;
  height: 50px;
}
</style>
