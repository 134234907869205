<template>
  <div>
    <v-row class="px-3 py-1">
      <v-col
        cols="12"
        class="pa-1 d-flex justify-space-between align-center"
      >
        {{ title }}
      </v-col>
    </v-row>

    <v-row
      v-for="(highlight, index) in highlights"
      :key="index"
      class="pa-1"
    >
      <v-col class="px-3">
        <v-card
          color="#191919"
          class="rounded-lg"
          elevation="15"
        >
          <div>
            <video-js
              :id="`highlightVideo${index}`"
              :ref="`highlightVideo${index}`"
              class="video-player-box"
              preload="auto"
            />
            <v-chip
              class="absolute-chip text-xs"
              color="#1d1d1dd4"
              small
            >
              {{ highlight.time_str }}
            </v-chip>
          </div>
          <v-card-actions class="d-block py-3">
            <div class="d-flex justify-space-between align-center mb-3">
              <div class="d-flex flex-column ">
                <span class="white--text">
                  {{ highlight.name }}
                </span>
                <span class="text-xs">
                  {{ highlight.type_str }} {{ t('tooltip.at_minute') }} {{ highlight.start_time_str }}
                </span>
              </div>

              <v-col
                cols="3"
                class="d-flex align-center justify-end pa-0"
                @click="shareFile(highlight.name, `${highlight.name}\n${highlight.type_str} at minute ${highlight.start_time_str}`, highlight.highlight_url, `Fanaty_${highlight.type_str}`, getLog(highlight.reference))"
              >
                <v-btn
                  icon
                  width="auto"
                >
                  <svg
                    width="25px"
                    height="25px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor-pointer"
                  >
                    <path
                      d="M20.33 3.66996C20.1408 3.48213 19.9035 3.35008 19.6442 3.28833C19.3849 3.22659 19.1135 3.23753 18.86 3.31996L4.23 8.19996C3.95867 8.28593 3.71891 8.45039 3.54099 8.67255C3.36307 8.89471 3.25498 9.16462 3.23037 9.44818C3.20576 9.73174 3.26573 10.0162 3.40271 10.2657C3.5397 10.5152 3.74754 10.7185 4 10.85L10.07 13.85L13.07 19.94C13.1906 20.1783 13.3751 20.3785 13.6029 20.518C13.8307 20.6575 14.0929 20.7309 14.36 20.73H14.46C14.7461 20.7089 15.0192 20.6023 15.2439 20.4239C15.4686 20.2456 15.6345 20.0038 15.72 19.73L20.67 5.13996C20.7584 4.88789 20.7734 4.6159 20.7132 4.35565C20.653 4.09541 20.5201 3.85762 20.33 3.66996ZM4.85 9.57996L17.62 5.31996L10.53 12.41L4.85 9.57996ZM14.43 19.15L11.59 13.47L18.68 6.37996L14.43 19.15Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </v-btn>
                <div class="d-flex flex-column">
                  <span class="cursor-pointer white--text">{{ t('tooltip.share') }}</span>
                </div>
              </v-col>
            </div>

            <div class="d-flex">
              <v-avatar
                color="primary"
                :class="highlight.user_avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="22"
              >
                <v-img
                  v-if="highlight.user_avatar"
                  :src="highlight.user_avatar"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(highlight.fullname) }}</span>
              </v-avatar>
              <div class="d-flex flex-column ml-2 justify-center">
                <span class="text-xs">{{ highlight.user_name }}</span>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script src="https://unpkg.com/video.js/dist/video.js"></script>
<script src="https://unpkg.com/@videojs/http-streaming/dist/videojs-http-streaming.js"></script>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { mdiShare} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'

// import { logUserActivity } from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useShare from '@core/utils/useShare'
import videojs from 'video.js'

export default {
  name: 'HighlightList',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    highlights: {
      type: Array,
      default: () => [],
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
      required: true,
    },
    ipAddress: {
      type: Object,
      default: () => {},
      required: true,
    },
    matchCode: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props) {
    const { shareFile } = useShare()
    const { userData } = useCryptoJs()
    const { t } = useUtils()

    const USER_ACT_SHARE_HIGHLIGHT_LANDING_APP = 'Share Highlight Landing App'

    const playerOptions = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: false,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-general-ohio.s3.us-east-2.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })

    const computedTitle = computed(() => props.title)
    const computedHighlights = computed(() => props.highlights)
    const computedIsPrivate = computed(() => props.isPrivate)
    const computedIpAddress  = computed(() => props.ipAddress)
    const computedMatchCode  = computed(() => props.matchCode)

    const getLog = (reference) => {
      const log = {
        ip_address: computedIsPrivate.value ? null : computedIpAddress.value.ip,
        user_id: computedIsPrivate.value ? userData.value.user_id : null,
        match_code: computedMatchCode.value,
        reference: reference,
        action: USER_ACT_SHARE_HIGHLIGHT_LANDING_APP,
      }

      return log
    }

    onMounted(async () => {
      computedHighlights.value.forEach((e, i) => {
        let playerOpt = playerOptions.value
        playerOpt.sources = []
        playerOpt.poster = e.thumbnail
        playerOpt.sources.push({
          type: 'video/mp4',
          src: e.highlight_url,
        })

        const getPlayer = videojs.getPlayer(`highlightVideo${i}`)
        if (getPlayer) getPlayer.dispose()
        const player = videojs(`highlightVideo${i}`, playerOpt)
      })
    })

    return {
      // data
      USER_ACT_SHARE_HIGHLIGHT_LANDING_APP,
      playerOptions,
      userData,

      // computed
      computedTitle,
      computedHighlights,
      computedIsPrivate,
      computedIpAddress,
      computedMatchCode,

      //methods
      avatarText,
      shareFile,
      getLog,

      // i18n
      t,

      // icons
      icons: {
        mdiShare,
        sendIcon: require('@/assets/images/svg/send.svg')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.absolute-chip {
  bottom: 110px;
  right: 15px;
  position: absolute;
  z-index: 1;
}

.video-js .vjs-tech {
  border-radius: 8px 8px 0px 0px;
}

.video-js .vjs-big-play-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://pngimage.net/wp-content/uploads/2018/06/svt-png-9.png');
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 50% calc(50% - 10px);
  border: none !important;
  box-shadow: none !important;
  &:before {
    content: "";
    display: none;
  }
  &:hover {
    background-color: transparent;
    opacity: .7;
  }
}
</style>
