<template>
  <svg
    :width="width"
    :height="height"
    viewBox="-3 0 32 32"
    version="1.1"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- <title
      :id="iconName"
      lang="en"
    >
      {{ iconName }} icon
    </title> -->
    <g id="icomoon-ignore"></g>
    <path
      d="M0 4.985v22.029h26.226v-22.029h-26.226zM4.196 25.966h-3.147v-4.196h3.147v4.196zM4.196 20.721h-3.147v-4.196h3.147v4.196zM4.196 15.476h-3.147v-4.196h3.147v4.196zM4.196 10.231h-3.147v-4.196h3.147v4.196zM20.98 11.979v13.987h-15.735v-19.931h15.735v5.944zM25.177 25.966h-3.147v-4.196h3.147v4.196zM25.177 20.721h-3.147v-4.196h3.147v4.196zM25.177 15.476h-3.147v-4.196h3.147v4.196zM25.177 10.231h-3.147v-4.196h3.147v4.196z"
      :fill="iconColor"
    ></path>
    <path
      d="M10.49 11.76v8.479l7.343-4.239-7.343-4.24zM11.539 13.577l4.196 2.423-4.196 2.423v-4.845z"
      :fill="iconColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'SendIcon',
  props: {
    iconName: {
      type: String,
      default: 'SendIcon',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>
