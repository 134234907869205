<template>
  <div>
    <v-row class="px-3 py-1">
      <v-col
        cols="12"
        class="pa-1 d-flex justify-space-between align-center"
      >
        <span class="d-flex align-center lighterGray--text">
          {{ title }}
          <v-icon
            v-if="titleIcon"
            class="ml-2"
          >
            {{ titleIcon }}
          </v-icon>
        </span>

        <v-btn
          v-if="showAction"
          class="pa-0 text-center no-bg-hover mr-1"
          style="
            text-transform: inherit;
            color: #FF3232;
            min-width: 60px;
            "
          text
          :ripple="false"
          @click="() => $emit('action')"
        >
          {{ actionText }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3 d-flex justify-space-between align-center"
      >
        <v-card
          color="#333333"
        >
          <v-card-title class="pb-2 text-sm">
            {{ cardTitle }}
          </v-card-title>
          <v-card-text class="text-xs lighterGray--text">
            {{ cardText }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  name: 'MatchCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    titleIcon: {
      type: String,
      default: '',
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: '',
    },
    cardTitle: {
      type: String,
      default: '',
    },
    cardText: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const computedTitle = computed(() => props.title)

    return {
      // computed
      computedTitle,

      avatarText,

      icons: {
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>
