import { ref } from '@vue/composition-api'
import { getUserPaymentMethods } from '@api'

export default function useMatchDetail() {
  const paymentMethods = ref(null)

  const fetchPaymentMethods = async () => {
    const response = await getUserPaymentMethods()
    paymentMethods.value = response.data
  }

  return {
    // data
    paymentMethods,

    // mEthods
    fetchPaymentMethods,
  }
}
