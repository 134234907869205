<template>
  <div>
    <v-row class="px-3 py-1">
      <v-col
        cols="12"
        class="pa-1 d-flex justify-space-between align-center"
      >
        <span class="d-flex align-center lighterGray--text">
          {{ title }}
          <v-icon
            v-if="titleIcon"
            class="ml-2"
          >
            {{ titleIcon }}
          </v-icon>
        </span>

        <v-btn
          v-if="showAction"
          class="pa-0 text-center no-bg-hover mr-1"
          style="
            text-transform: inherit;
            color: #FF3232;
            min-width: 60px;
            "
          text
          :ripple="false"
          @click="() => $emit('action')"
        >
          {{ actionText }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-for="(organizer, index) in organizers"
      :key="index"
      class="pa-1"
      :class="{'b-bottom': index !== organizers.length - 1}"
    >
      <v-col
        cols="12"
        class="py-2 px-3 d-flex justify-space-between align-center"
      >
        <div class="d-flex">
          <v-avatar
            color="#333333"
            :class="organizer.avatar ? '' : 'white--text'"
            size="50"
          >
            <v-img
              v-if="organizer.avatar"
              :src="organizer.avatar"
            ></v-img>
            <span
              v-else
              class="font-weight-medium"
            >{{ avatarText(organizer.fullname) }}</span>
          </v-avatar>
          <div class="d-flex flex-column ml-2 justify-center">
            <span class="white--text">{{ organizer.fullname }}</span>
          </div>
        </div>
        <span class="text-xs"></span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'OrganizerList',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    titleIcon: {
      type: String,
      default: '',
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: '',
    },
    organizers: {
      type: Array,
      default: () => [],
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedTitle = computed(() => props.title)

    return {
      // computed
      computedTitle,

      avatarText,
    }
  },
}
</script>
