<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="420px"
    persistent
  >
    <v-card
      class="pa-3 rounded-lg"
      rounded
      style="width: 100%"
      color="#191919"
    >
      <v-form
        ref="form"
        @submit.prevent="onSave"
      >
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          dense
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ t('player_actions.players_quantity') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('update:is-modal-open', false)">
            {{ icons.mdiClose }}
          </v-icon>
        </v-app-bar>

        <v-card-text class="mt-5 pb-3 px-2 justify-center d-flex flex-column">
          <v-text-field
            v-model.number="quantityPlayer"
            :placeholder="t('player_actions.players_quantity')"
            dense
            outlined
            rounded
            :rules="[required, integerValidator, between(quantityPlayer, minPlayers, 50)]"
            @keypress.enter="onSave"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center py-3 px-2">
          <v-btn
            class="text-xs text-capitalize rounded-lg"
            color="secondary"
            block
            dark
            type="submit"
          >
            {{ t('tooltip.submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import { ref, computed, watch } from '@vue/composition-api'
import { mdiPlus, mdiDeleteOutline, mdiClose, mdiMenuDown } from '@mdi/js'
import { required, integerValidator, between } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'

export default {
  name: 'ChangeQuantity',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    minPlayers: {
      type: Number,
      default: 12,
      required: false,
    },
    quantity: {
      type: Number,
      default: 12,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const quantityPlayer = ref(null)

    const computedIsModalOpen = computed(() => props.isModalOpen)
    const computedMinPlayers = computed(() => props.minPlayers)

    const validateMinPlayer = v => {
      if (v >= computedMinPlayers.value) return true

      return `Número de jugadores debe ser mayor o igual a ${computedMinPlayers.value}`
    }

    const onSave = () => {
      if (form.value.validate()) emit('change-quantity', quantityPlayer.value)
    }

    watch([computedIsModalOpen], () => {
      if (computedIsModalOpen.value) quantityPlayer.value = props.quantity
    })

    return {
      // data
      form,
      quantityPlayer,

      // computed
      computedMinPlayers,
      computedIsModalOpen,

      // methods
      onSave,

      // validations
      required,
      integerValidator,
      validateMinPlayer,
      between,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }
</style>
