<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!-- header -->
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-0 py-1">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <div class="d-flex flex-column ml-2">
                  <div class="white--text d-flex align-center">
                    <v-btn
                      class="pa-0 mr-1 text-center no-bg-hover"
                      :class="{ 'cursor-default': dataComputed.is_organizer }"
                      style="
                        text-transform: inherit;
                        min-width: 60px;
                        height: 25px !important;
                        font-size: 17px;
                      "
                      text
                      :ripple="false"
                      @click="openClose(true)"
                    >
                      {{ dataComputed.match_name }}
                      <v-icon
                        v-if="dataComputed.is_organizer"
                        color="white"
                        size="18"
                        class="ml-2"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>

                    <!-- <span class="text-body-1">{{ dataComputed.match_name }}</span> -->
                  </div>
                  <div>
                    <span class="">{{ dataComputed.match_date_str }}</span>
                    <!-- <span class=" pl-1">• {{ dataComputed.match_views }} views</span> -->
                  </div>
                </div>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-1 no-bg-hover"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin('NO')"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-menu
                  class="ms-2"
                  :is-private="privateLanding"
                  @report="openCloseReport"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <!-- <video-js
        v-if="dataComputed.match_recap_video"
        id="matchRecapVideo"
        ref="matchRecapVideo"
        class="video-player-box"
        controls
        preload="auto"
        width="640"
        height="268"
      /> -->

      <!-- <v-carousel
        v-else
        height="250px"
        width="auto"
        hide-delimiters
        cycle
        show-arrows-on-hover
        :show-arrows="false"
      >
        <v-btn
          v-if="activeComputed"
          icon
          style="
            position: absolute;
            top: 32%;
            right: 50%;
            transform: translate(50%, 50%);
            z-index: 1;
          "
          @click="linkVideo(dataComputed.match_status)"
        >
          <span
            name="full'video"
            style="
              color: #FFFFFF;
              background-color: #00000080;
              border-radius: 100px;
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <v-icon>{{ icons.mdiMovieOpenPlayOutline }}</v-icon>
          </span>
        </v-btn>

        <div
          v-if="!activeComputed"
          class="absolute-span"
        >
          <v-icon
            v-if="dataComputed.match_status !== 'Processing'"
            :color="resolvePosterIconColor(dataComputed.poster_status)"
            size="12"
          >
            {{ resolvePosterIcon(dataComputed.poster_status) }}
          </v-icon>

          <v-progress-circular
            v-else
            color="white"
            :size="15"
            :width="3"
            indeterminate
          />

          <span
            class="text-xs ml-1 white--text"
            color="#1d1d1dd4"
            small
          >
            {{ dataComputed.poster_label }}
          </span>
        </div>

        <v-chip
          class="absolute-chip text-xs white--text"
          color="#1d1d1dd4"
          small
        >
          {{ dataComputed.match_time }}
        </v-chip>

        <div v-if="dataComputed.match_status === 'Scheduled' && dataComputed.field_images.length">
          <v-carousel-item
            v-for="
              (item,
               index)
                in
                  dataComputed.field_images"
            :key="index"
            :src="item"
            style="opacity: 0.7;"
            eager
          />
        </div>

        <div v-else-if=" dataComputed.match_status === 'Scheduled' && dataComputed.complex_images.length">
          <v-carousel-item
            v-for="
              (item,
               index)
                in
                  dataComputed.complex_images"
            :key="index"
            :src="item"
            style="opacity: 0.7;"
            eager
          />
        </div>

        <v-carousel-item
          v-else-if="dataComputed.thumbnail"
          :src="dataComputed.thumbnail"
          eager
        />

        <div v-else-if="dataComputed.thumbnails && dataComputed.thumbnails.length">
          <v-carousel-item
            v-for="(item, index) in dataComputed.thumbnails"
            :key="index"
            :src="item"
            style="opacity: 0.7;"
            eager
          />
        </div>
      </v-carousel> -->

      <!-- actions -->
      <v-card-text>
        <v-row
          class="py-3"
          :class="{'px-1': activeComputed, 'px-3': !activeComputed}"
          :justify="activeComputed ? 'space-around' : 'start'"
        >
          <v-col
            :cols="activeComputed ? '4' : '6'"
            :md="activeComputed ? '4' : '6'"
            :sm="activeComputed ? '4' : '6'"
            class="pa-1 d-flex align-center"
            :class="{'justify-center': activeComputed, 'justify-start': !activeComputed}"
            @click="onShare"
          >
            <video-action
              :title="t('landing.share_game')"
              :icon="icons.mdiTrayArrowUp"
              :is-row="!activeComputed"
            />
          </v-col>

          <v-col
            v-if="activeComputed"
            cols="4"
            md="4"
            sm="4"
            class="pa-1 d-flex justify-center align-center"
            @click="linkVideo(dataComputed.match_status)"
          >
            <video-action
              :title="t('landing.full_video')"
              :icon="icons.mdiMovieOpenPlayOutline"
            />
          </v-col>

          <v-col
            v-if="activeComputed"
            cols="4"
            md="4"
            sm="4"
            class="pa-1 d-flex justify-centern align-center"
          >
            <video-action
              v-if="false"
              :title="t('landing.rate_players')"
              :icon="icons.mdiStarOutline"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-divider style="color: #191919" /> -->

      <!-- location -->
      <v-card-text v-if="!activeComputed">
        <v-row
          class="px-3 py-1"
          @click="openTab(dataComputed.complex_location, '_blank')"
        >
          <v-col
            cols="12"
            class="pa-1 d-flex justify-space-between align-center"
          >
            <div class="d-flex">
              <v-icon
                class="cursor-pointer"
                style="
                  background-color: #333333;
                  border-radius: 100px;
                  width: 50px;
                  height: 50px;
                "
              >
                {{ icons.mdiMapMarker }}
              </v-icon>
              <div class="d-flex flex-column ml-2 cursor-pointer">
                <span class="white--text">{{ dataComputed.complex_name }}</span>
                <span class="text-xs">{{ dataComputed.pitch_name }}</span>
                <span class="text-xs">{{ dataComputed.complex_address }}</span>
              </div>
            </div>
            <span class="text-xs"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider
        v-if="!activeComputed"
        style="color: #191919"
      />

      <!-- player list -->
      <!-- <v-card-text v-if="dataComputed.players && dataComputed.players.length">
        <player-list
          :title="dataComputed.players_qty_label"
          :players="dataComputed.players"
          :player-quantity="dataComputed.players_qty"
          :player-joined-quantity="dataComputed.players_joined_qty"
          :show-actions="true"
          :show-change="dataComputed.is_organizer && (dataComputed.match_status === 'Scheduled')"
          :is-private="privateLanding"
          :is-organizer="dataComputed.is_organizer"
          :is-player="dataComputed.is_player"
          @refresh-match="fetchMatch"
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.players && dataComputed.players.length"
        style="color: #191919"
      /> -->

      <!-- highlight list -->
      <v-card-text v-if="dataComputed.highlights && false">
        <highlight-list
          :title="`${t('highlights.highlights').toUpperCase()} (${dataComputed.highlights.length})`"
          :highlights="dataComputed.highlights"
          :is-private="privateLanding"
          :ip-address="ipAddress"
          :match-code="matchCode"
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.highlights && false"
        style="color: #191919"
      />

      <!-- v-if="dataComputed.organizer && dataComputed.organizer.length" -->
      <v-card-text>
        <group-list
          :title="t('groups.groups').toUpperCase()"
          :data="dataComputed.circles"
          no-data-text="No group assigned."
          show-action
          action-text="Edit Groups"
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.organizer && dataComputed.organizer.length"
        style="color: #191919"
      />

      <!-- Match visibility -->
      <v-card-text v-if="dataComputed.organizer && dataComputed.organizer.length">
        <match-card
          title="MATCH VISIBILITY"
          show-action
          action-text="Make Public"
          card-title="Private Match"
          card-text="A private match would not be displayed publicly. A public matched, will be visible to anyone."
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.organizer && dataComputed.organizer.length"
        style="color: #191919"
      />

      <!-- Video recording -->
      <v-card-text v-if="dataComputed.organizer && dataComputed.organizer.length">
        <match-card
          title="VIDEO RECORDING"
          show-action
          action-text="Disable Video"
          card-title="Premium Video"
          card-text="The match will be recorded with our artificial intelligent video camera."
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.organizer && dataComputed.organizer.length"
        style="color: #191919"
      />

      <!-- match type -->
      <v-card-text v-if="dataComputed.organizer && dataComputed.organizer.length">
        <match-card
          title="MATCH TYPE"
          :title-icon="icons.mdiHelpCircleOutline"
          show-action
          action-text="Change Type"
          card-title="Ranked Match"
          card-text="A ranked match affects all levels and rankings of players. An unranked match does not."
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.organizer && dataComputed.organizer.length"
        style="color: #191919"
      />

      <!-- organizer list -->
      <v-card-text v-if="dataComputed.organizer && dataComputed.organizer.length">
        <organizer-list
          :title="t('player_actions.organizer').toUpperCase()"
          show-action
          action-text="Edit Oganizers"
          :organizers="dataComputed.organizer"
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.organizer && dataComputed.organizer.length"
        style="color: #191919"
      />

      <!-- complex info -->
      <v-card-text
        v-if="dataComputed.complex_images && dataComputed.complex_images.length && dataComputed.complex_description"
        style="margin-bottom: 86px;"
      >
        <v-row class="px-3 py-1">
          <v-col
            cols="12"
            class="pa-1 d-flex justify-space-between align-center lighterGray--text"
          >
            {{ t('facilities.facility').toUpperCase() }}
          </v-col>
        </v-row>
        <v-row class="pa-1">
          <v-col
            cols="12"
            class="px-3 d-flex justify-space-between align-center"
          >
            <v-carousel
              height="205px"
              width="auto"
              hide-delimiter-background
              cycle
              class="rounded-lg"
              :show-arrows="false"
            >
              <div>
                <v-carousel-item
                  v-for=" (item, index) in dataComputed.complex_images"
                  :key="index"
                  :src="item"
                  :style="dataComputed.match_type === 'O' ? 'opacity: 0.7;' : ''"
                  eager
                />
              </div>
            </v-carousel>
          </v-col>

          <v-col
            cols="12"
            class="px-3 lighterGray--text"
            v-html="textBreakLine(dataComputed.complex_description)"
          />
        </v-row>
      </v-card-text>
      <!-- <v-divider
        v-if="dataComputed.complex_images && dataComputed.complex_images.length && dataComputed.complex_description"
        style="color: #191919"
      /> -->

      <!-- Confirm booking -->
      <v-card-actions
        v-if="dataComputed.reservation_status === 'pending' || (dataComputed.reservation_status === 'confirm' && !dataComputed.is_player)"
        class="join-game py-3 d-flex- flex-column"
      >
        <v-btn
          color="secondary text-capitalize mb-2"
          block
          style="border-radius: 33px;"
          @click="onJoinGame"
        >
          {{ dataComputed.reservation_status === 'pending' ? 'Confirm booking' : 'Join match' }}
        </v-btn>

        <v-btn
          color="secondary text-capitalize"
          block
          text
          style="border-radius: 33px;"
        >
          Decline
        </v-btn>
      </v-card-actions>
    </div>

    <change-name
      v-model="isDialogChangeName"
      :name="dataComputed.match_name"
      @open-close="openClose"
      @change-name="onChangeName"
    />

    <report
      v-model="isDialogReport"
      @open-close="openCloseReport"
      @report-issue="onReportIssue"
    />
  </div>
</template>

<script src="https://unpkg.com/video.js/dist/video.js"></script>
<script src="https://unpkg.com/@videojs/http-streaming/dist/videojs-http-streaming.js"></script>
<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiTrayArrowUp,
  mdiDotsVertical,
  mdiCircle,
  mdiMapMarker,
  mdiCancel,
  mdiPlay,
  mdiStarOutline,
  mdiVideo,
  mdiPencil,
  mdiMovieOpenPlayOutline,
  mdiHelpCircleOutline,
} from '@mdi/js'
import { ref, computed, watch, onMounted, onUnmounted } from '@vue/composition-api'
import { useRouter, openTab, downloadAppByOS } from '@core/utils'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  getMatchOpenCode,
  getMatchCode,
  editMatchInfo,
  joinGame,
  reportIssue,
  logUserActivity,
} from '@api'
import { success } from '@/@core/utils/toasted'
import useShare from '@/@core/utils/useShare'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import useVideoData from '@core/utils/useVideoData'
import videojs from 'video.js'

import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import VideoAction from '@/views/match-detail/components/video-action/VideoAction.vue'
import PlayerList from '@/views/match-detail/components/player-list/PlayerList.vue'
import OrganizerList from '@/views/match-detail/components/organizer-list/OrganizerList.vue'
import HighlightList from '@/views/match-detail/components/highlight-list/HighlightList.vue'
import SendIcon from '@/views/match-detail/components/icons/SendIcon.vue'
import FilmPlay from '@/views/match-detail/components/icons/FilmPlay.vue'
import ChangeName from '@/views/match-detail/components/change-name/ChangeName.vue'
import Report from '@/views/match-detail/components/report/Report.vue'
import MatchCard from '@/views/match-detail/components/match-card/MatchCard.vue'
import GroupList from '@/views/match-detail/components/group-list/GroupList.vue'

import useMatchDetail from './useMatchDetail'

export default {
  components: {
    AppBarUserMenu,
    VideoAction,
    PlayerList,
    OrganizerList,
    HighlightList,
    SendIcon,
    FilmPlay,
    ChangeName,
    Report,
    MatchCard,
    GroupList,
  },
  setup() {
    const { route, router } = useRouter()
    const { t } = useUtils()
    const { decryptData, userData } = useCryptoJs()
    const { logData, updateLogData, clearLogData } = useLogData()
    const { videoData, updateVideoData, clearVideoData } = useVideoData()
    const { shareURL } = useShare()
    const { paymentMethods } = useMatchDetail()

    const USER_ACT_VISIT_MATCH_LANDING_APP = 'Visit Match Landing App'
    const USER_ACT_SHARE_MATCH_LANDING_APP = 'Share Match Landing App'
    const USER_ACT_VIEW_MATCH_LANDING_APP = 'View Match Landing App'
    const ipAddress = ref({})

    const dataLanding = ref({})
    const dataComputed = computed(() => dataLanding.value)
    const activeComputed = computed(() => dataComputed.value.match_status === 'Active')
    const basicComputed = computed(() => dataComputed.value.match_status === 'Basic')
    const computedMatchCode = computed(() => JSON.parse(localStorage.getItem('matchCode')))
    const matchCode = ref(null)
    const videoId = ref(null)
    const privateLanding = ref(false)
    const isDialogChangeName = ref(false)
    const isDialogReport = ref(false)

    const playerOptions = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: false,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-general-ohio.s3.us-east-2.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })

    const onLogin = async (isJoinGame = 'NO') => {
      logData.value.joinGame = isJoinGame
      logData.value.matchCode = computedMatchCode.value
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onJoinGame = async () => {
      if (privateLanding.value) {
        const resp = await joinGame({ match_code: computedMatchCode.value })
        if (resp.ok) {
          isDialogChangeName.value = false
          await fetchMatch()
        }
      } else onLogin('YES')
    }

    const resolvePosterIcon = status => {
      if (status === 'Active') return mdiPlay
      if (status === 'Processing') return mdiCircle
      if (status === 'Basic' || status === 'Recording') return mdiCircle
      if (status === 'Cancelled') return mdiCancel

      return mdiCircle
    }

    const resolvePosterIconColor = status => {
      if (status === 'Basic') return 'info'
      if (status === 'Active' || status === 'Premiun' || status === 'Recording' || status === 'Cancelled') return 'secondary'

      return 'secondary'
    }

    const fetchLogUserActivity = async action => {
      await logUserActivity({
        ip_address: privateLanding.value ? null : ipAddress.value.ip,
        user_id: privateLanding.value ? userData.value.user_id : null,
        match_code: matchCode.value,
        reference: null,
        action: action,
      })
    }

    const onShare = () => {
      shareURL(dataComputed.value.match_name, `${dataComputed.value.match_name}\n${dataComputed.value.match_date_str}\n\n${dataComputed.value.share_link}`, dataComputed.value.share_link)

      // fetchLogUserActivity(USER_ACT_SHARE_MATCH_LANDING_APP)
    }

    const linkVideo = status => {
      if (status && status !== 'Active') return false

      // window.open(`${window.location.origin}/app-redirect/${videoData.value.video_id}`, '_blank')
      downloadAppByOS(
        `${window.location.origin}/app-redirect/${videoId.value}`,
        `${window.location.origin}/app-redirect/${videoId.value}`,
        `${window.location.origin}/app-redirect/${videoId.value}`,
        true,
      )

      // fetchLogUserActivity(USER_ACT_VIEW_MATCH_LANDING_APP)

      return true
    }

    const openClose = async val => {
      isDialogChangeName.value = val
      if (dataComputed.value.is_organizer) isDialogChangeName.value = val
      else isDialogChangeName.value = false
    }

    const onChangeName = async val => {
      const resp = await editMatchInfo(computedMatchCode.value, { match_name: val })
      if (resp.ok) {
        isDialogChangeName.value = false
        await fetchMatch()
      }
    }

    const openCloseReport = async val => {
      isDialogReport.value = val
    }

    const onReportIssue = async (val, email) => {
      const resp = await reportIssue(matchCode.value, val)
      if (resp.ok) {
        isDialogReport.value = false
        success(resp.message)
        await fetchMatch()
      }
    }

    const fetchMatch = async () => {
      let response = null
      if (userData.value) {
        response = await getMatchCode(matchCode.value)
      } else {
        response = await getMatchOpenCode(matchCode.value)
      }

      if (response.ok) {
        dataLanding.value = response.data

        if (dataLanding.value.show_video_type) {
          videoData.value.video = null
          videoData.value.match = matchCode.value
          videoData.value.price = dataLanding.value.match_price
          videoData.value.paymentMethodsParams = paymentMethods.value
          videoData.value.landingParams = dataLanding.value
          await updateVideoData(videoData.value)

          router.push({ name: 'views-video-quality' })
        }

        if (dataLanding.value.reference) {
          const videoReference = decryptData(dataLanding.value.reference)
          if (videoReference.video_id) videoId.value = videoReference.video_id
        }

        if (dataLanding.value.match_status === 'Cancelled') {
          router.push({ name: 'video-cancelled' })
        } else {
          let player = null
          let getPlayer = videojs.getPlayer('matchRecapVideo')
          if (getPlayer) {
            const vjs = document.getElementById('matchRecapVideo')
          }

          if (!getPlayer) {
            if (dataLanding.value.thumbnail) playerOptions.value.poster = dataLanding.value.thumbnail

            // type: 'application/dash+xml',
            // src: 'https://fanaty-staging-ohio.s3.us-east-2.amazonaws.com/ticket-2118/hls/a.m3u8',
            if (dataLanding.value.match_recap_video) {
              playerOptions.value.sources.push({
                type: 'application/x-mpegURL',
                src: dataLanding.value.match_recap_video,
              })

              setTimeout(() => {
                player = videojs('matchRecapVideo', playerOptions.value)
              }, 200)
            }
          }
        }
      }
    }

    const textBreakLine = text => {
      if (text) return text.split('.').filter(e => e).map(e => (`<p class="ma-0">${e}.</p><br />`)).join('')

      return `<span></span>`
    }

    watch([route], async () => {
      const { name } = route.value

      // privateLanding.value = name === 'match'

      await fetchMatch()

      await fetch('https://api.ipify.org/?format=json')
        .then(ipResponse => ipResponse.json())
        .then(async ipData => ipAddress.value = ipData)
    })

    onMounted(async () => {
      const { name } = route.value

      // privateLanding.value = name === 'match'

      if (name === 'public') await clearLogData()
      await clearVideoData()

        const { reauth, code } = route.value.params
        if (reauth) window.location.reload()
        matchCode.value = code
      localStorage.setItem('matchCode', JSON.stringify(code))

      await fetchMatch()

      if (!userData.value) {
        await fetch('https://api.ipify.org/?format=json')
          .then(ipResponse => ipResponse.json())
          .then(async ipData => ipAddress.value = ipData)
      }

      // fetchLogUserActivity(USER_ACT_VISIT_MATCH_LANDING_APP)
    })

    onUnmounted(() => {
      if (videojs.getPlayer('matchRecapVideo')) videojs.getPlayer('matchRecapVideo').dispose()
    })

    return {
      // data
      USER_ACT_VISIT_MATCH_LANDING_APP,
      USER_ACT_SHARE_MATCH_LANDING_APP,
      USER_ACT_VIEW_MATCH_LANDING_APP,
      ipAddress,
      userData,

      playerOptions,
      matchCode,
      privateLanding,
      isDialogChangeName,
      isDialogReport,

      // computed
      dataComputed,
      activeComputed,
      basicComputed,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      avatarText,
      openTab,
      onLogin,
      shareURL,
      resolvePosterIcon,
      resolvePosterIconColor,
      onShare,
      linkVideo,
      fetchMatch,
      openClose,
      onChangeName,
      openCloseReport,
      onReportIssue,
      onJoinGame,
      textBreakLine,

      t,

      icons: {
        mdiTrayArrowUp,
        mdiDotsVertical,
        mdiCircle,
        mdiMapMarker,
        mdiCancel,
        mdiPlay,
        mdiStarOutline,
        mdiVideo,
        mdiPencil,
        mdiMovieOpenPlayOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
@import '@core/preset/preset/pages/landing.scss';

.auth-wrapper {
  background-color: #222222;
}

.theme--dark.v-divider {
  border-color: #333333;
}
</style>
